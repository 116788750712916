const ecSdkReducer = (state = {}, action) => { 
    if (action.type === "EC_SDK") {
        return {
            ...state,
            ...action.payload
        }
    } else {
        return state
    }
}

export default ecSdkReducer