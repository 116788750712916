// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const persistedState = localStorage.getItem('userData') ? {auth: {userData: JSON.parse(localStorage.getItem('userData'))}} : {}
if (localStorage.getItem('userData')) {
    global.USER = {data: JSON.parse(localStorage.getItem('userData'))}
}

const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(...middleware)))
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))

export { store }
