// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** React Tour
import { TourProvider } from '@reactour/tour'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/scss/core.scss'

import './@core/scss/base/bootstrap-extended/_variables.scss'
import './assets/scss/style.scss'

/* React Perfect Scrollbar */
import '@styles/react/apps/app-invoice.scss'
import "@styles/react/libs/tables/react-dataTable-component.scss"
// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'

/* Core styles */
import './mainDashboard.css'

// import { pdfjs } from 'react-pdf'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

import 'react-confirm-alert/src/react-confirm-alert.css'
import '@styles/react/libs/flatpickr/flatpickr.scss'

import loadable from 'react-loadable'
import './i18n'
import { Badge } from 'reactstrap'
// ** sentry
// import * as Sentry from "@sentry/react"
// import { Integrations } from "@sentry/tracing"
// // if (process.env.NODE_ENV !== "development") {

// Sentry.init({
//   dsn: "https://9fc935f9f35e4bddaead413061522b6d@o1117173.ingest.sentry.io/6151042",
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.NODE_ENV.concat("educase"),
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })
// // }

const LazyApp = loadable({
  loader: () => import(/* webpackChunkName: 'LazyAppMainApp' */'./App'),
  loading: () => (<></>)
})

const steps = [
  {
    selector: '.first-step',
    content: 'This is my first Step'
  }
]

function Close({ onClick }) {
  return (
    <button
      onClick={onClick}
      style={{ position: 'absolute', right: 0, top: 0, background: 'transparent', border: 'none', color: 'var(--primary)', fontSize: 20 }}
    >
      <Badge style={{ borderRadius: '50%' }}>
        ×
      </Badge>
    </button>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <TourProvider steps={steps} components={{ Close }} scrollSmooth styles={{
              arrow: base => ({ ...base, color: 'var(--primary)' }),
              close: base => ({ ...base, color: 'var(--primary)' }),
              dot: (base, { current }) => ({
                ...base,
                background: current ? 'var(--primary)' : '#fff',
                border: '1px solid #ccc'
              }),
              popover: base => ({ ...base, borderRadius: 5, paddingTop: 35, minWidth: 293, maxWidth: 293, textAlign: 'center' }),
              badge: base => ({ ...base, background: 'var(--primary)' })
            }}>
              <LazyApp />
              <ToastContainer autoClose={2000} hideProgressBar={true} pauseOnHover={true} position="top-center" />
            </TourProvider>
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
