const classDropdownReducer = (state = {}, action) => {

    if (action.type === "CLASS_DROPDOWN") {
        return {
            // ...state,
            ...action.payload
        }
    } else {
        return state
    }
}

export default classDropdownReducer