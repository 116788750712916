// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import appData from './appData'
import schoolData from './schoolData'
import ecSdk from './ecSdk'
import invoice from '@src/views/modules/dashboard/analytics/store/reducer'
import moduleLogUpdateReducer from './moduleLogUpdate'
import classDropdown from './classDropdown'
// import navLinkClickedReducer from './NavLinkClicked'
// import calendar from '@src/views/modules/apps/calendar/store/reducer'
// import ecommerce from '@src/views/modules/apps/ecommerce/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  appData,
  schoolData,
  ecSdk,
  classDropdown,
  // navLinkClickedReducer,
  moduleLogUpdateReducer,
  invoice
  // calendar,
  // ecommerce
})

export default rootReducer
