const schoolDataReducer = (state = {}, action) => {
    if (action.type === "SCHOOL_DATA") {
        return {
            ...state,
            ...action.payload
        }
    } else {
        return state
    }
}

export default schoolDataReducer